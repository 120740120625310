import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../components/SideBar';

const Home = () => {
  return (
    <div className="container text-center mt-5">
      <Sidebar />
    </div>
  );
};

export default Home;