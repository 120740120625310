import React, { useState, useEffect } from 'react';
import '../components/css/SideBar.css';
import { FaStore, FaHome, FaLayerGroup, FaRegImage, FaInstagram, FaYoutube, FaTwitch, FaSmile, FaFileAlt } from 'react-icons/fa';
import SideDob3 from '../components/img/cloud-logo-1.png';
import Overlay1 from '../components/img/overlay/dragon-overlay.png';
import Overlay2 from '../components/img/overlay/thunder-overlay.gif';
import ComeceAqui from '../components/img/comece-btn.gif';
import Emoticon1 from '../components/img/emoticon/dob3-dragon.png';
import Emoticon2 from '../components/img/emoticon/dob3-fire.png';
import Emoticon3 from '../components/img/emoticon/dog-gun.gif';
import Emoticon4 from '../components/img/emoticon/emoji-guy.png';
import Emoticon5 from '../components/img/emoticon/eye-tiger.gif';
import Emoticon6 from '../components/img/emoticon/linus-music.gif';
import Emoticon7 from '../components/img/emoticon/ninja-emoti.png';
import Emoticon8 from '../components/img/emoticon/nyan-cat.gif';
import Emoticon9 from '../components/img/emoticon/old-guy.png';
import Emoticon10 from '../components/img/emoticon/punk.png';
import Emoticon11 from '../components/img/emoticon/suspect-spin.gif';
import Emoticon12 from '../components/img/emoticon/xdob3-emoti.gif';
import Logo1 from '../components/img/logoDob3/locuções.png';
import Logo2 from '../components/img/logoDob3/mecanica.png';
import Logo3 from '../components/img/logoDob3/professora.png';
import Logo4 from '../components/img/logoDob3/psicologia.png';
import Comercio1 from '../components/img/comercio/burguer-1.png';
import Comercio2 from '../components/img/comercio/pizzaria-1.png';


const Sidebar = () => {
  const [activeContent, setActiveContent] = useState('');

  // Recupera o estado ativo do localStorage quando o componente é montado
  useEffect(() => {
    const savedContent = localStorage.getItem('activeContent');
    if (savedContent) {
      setActiveContent(savedContent);
    }
  }, []);

  // Salva o estado ativo no localStorage sempre que ele é alterado
  const handleContentChange = (content) => {
    setActiveContent(content);
    localStorage.setItem('activeContent', content);
  };

  return (
    <div className="d-flex">
      <div className="sidebar d-flex flex-column align-items-center">
        <div className="logo my-4">
          <h2>
            <img src={SideDob3} alt="Logo" className="logo-class" />
          </h2>
        </div>
        <nav className="nav flex-column">
          <a href="#inicio" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('inicio')}>
            <FaHome className="me-2" /> Inicio
          </a>
          <a href="#comercio" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('comercio')}>
            <FaStore className="me-2" /> Comércio
          </a>
          <a href="#layout" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('logo')}>
            <FaLayerGroup className="me-2" /> Logo
          </a>
          <a href="#overlay" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('overlay')}>
            <FaRegImage className="me-2" /> Overlay
          </a>
          <a href="#instagram" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('instagram')}>
            <FaInstagram className="me-2" /> Instagram
          </a>
          <a href="#youtube" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('youtube')}>
            <FaYoutube className="me-2" /> YouTube
          </a>
          <a href="#twitch" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('twitch')}>
            <FaTwitch className="me-2" /> Twitch
          </a>
          <a href="#emoticon" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('emoticon')}>
            <FaSmile className="me-2" /> Emoticon
          </a>
          <a href="#landingpage" className="nav-link text-white d-flex align-items-center" onClick={() => handleContentChange('landingpage')}>
            <FaFileAlt className="me-2" /> Landing Page
          </a>
        </nav>
      </div>

      {/* INICIO */}
      <div className="inicio-container">
        {activeContent === 'inicio' && (
          <div className="inicio-content">
            <h1 className="display-4 fw-bold">Sua solução completa de design</h1>
            <p className="lead my-1">
            "Sonhe, planeje, conquiste"
            </p>
            <button className="btnComece btn-lg d-flex align-items-center justify-content-center" onClick={() => handleContentChange('comercio')}>
              <img src={ComeceAqui} alt="Comece" />
            </button>
          </div>
        )}
      </div>

      {/* COMERCIO */}
      <div className="comercio-container">
        {activeContent === 'comercio' && (
          <div className="comercio-content">
            <h3>Conteúdo de Comércio</h3>
            <img src={Comercio1} alt="comercio 1" className="comercio-image" />
            <img src={Comercio2} alt="comercio 2" className="comercio-image" />
          </div>
        )}
      </div>

      {/* Outros conteúdos... */}
      {/* Logo */}
      <div className="logo-container">
        {activeContent === 'logo' && (
          <div className="logo-content">
            <h3>Conteúdo de Logo</h3>
            <img src={Logo1} alt="logo 1" className="logo-image" />
            <img src={Logo2} alt="logo 2" className="logo-image" />
            <img src={Logo3} alt="logo 3" className="logo-image" />
            <img src={Logo4} alt="logo 4" className="logo-image" />
          </div>
        )}
      </div>

      {/* OVERLAY */}
      <div className="overlay-container">
        {activeContent === 'overlay' && (
          <div className="overlay-content">
            <h3>Conteúdo de Overlay</h3>
            <img src={Overlay1} alt="Exemplo" className="content-image" />
            <img src={Overlay2} alt="Exemplo" className="content-image" />
          </div>
        )}
      </div>

      {/* INSTAGRAM */}
      <div className="instagram-container">
        {activeContent === 'instagram' && (
          <div className="instagram-content">
            <h3>Conteúdo de Instagram</h3>
            <img src={Overlay1} alt="Exemplo" className="content-image" />
            <video controls className="content-video">
              <source src={Overlay2} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        )}
      </div>

      {/* YOUTUBE */}
      <div className="youtube-container">
        {activeContent === 'youtube' && (
          <div className="youtube-content">
            <h3>Conteúdo de YouTube</h3>
            <img src={Overlay1} alt="Exemplo" className="content-image" />
            <video controls className="content-video">
              <source src={Overlay2} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        )}
      </div>

      {/* TWITCH */}
      <div className="twitch-container">
        {activeContent === 'twitch' && (
          <div className="twitch-content">
            <h3>Conteúdo de Twitch</h3>
            <img src={Overlay1} alt="Exemplo" className="content-image" />
            <video controls className="content-video">
              <source src={Overlay2} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        )}
      </div>

      {/* EMOTICON */}
<div className="emoticon-container">
  {activeContent === 'emoticon' && (
    <div className="emoticon-content">
      <h3>Emoticons</h3>
      <div className="emoticon-grid">
        <img src={Emoticon1} alt="Emoticon 1" className="emoticon-image" />
        <img src={Emoticon2} alt="Emoticon 2" className="emoticon-image" />
        <img src={Emoticon3} alt="Emoticon 3" className="emoticon-image" />
        <img src={Emoticon4} alt="Emoticon 4" className="emoticon-image" />
        <img src={Emoticon5} alt="Emoticon 5" className="emoticon-image" />
        <img src={Emoticon6} alt="Emoticon 6" className="emoticon-image" />
        <img src={Emoticon7} alt="Emoticon 7" className="emoticon-image" />
        <img src={Emoticon8} alt="Emoticon 8" className="emoticon-image" />
        <img src={Emoticon9} alt="Emoticon 9" className="emoticon-image" />
        <img src={Emoticon10} alt="Emoticon 10" className="emoticon-image" />
        <img src={Emoticon11} alt="Emoticon 11" className="emoticon-image" />
        <img src={Emoticon12} alt="Emoticon 12" className="emoticon-image" />
      </div>
    </div>
  )}
</div>


      {/* LANDING PAGE */}
      <div className="landingpage-container">
        {activeContent === 'landingpage' && (
          <div className="landingpage-content">
            <h3>Conteúdo de Landing Page</h3>
            <img src={Overlay1} alt="Exemplo" className="content-image" />
            <video controls className="content-video">
              <source src={Overlay2} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
